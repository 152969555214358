import {Map} from "immutable";
import {IHolding} from "./main/api/HoldingsApi";
import {
    adminClientsInfoStateSelectorInner,
    adminUsersInfoStateSelectorInner,
} from "./main/components/admin/AdminReducer";
import {relatedPlansInner} from "./main/components/client/ClientReducer";
import {RequestState} from "./main/components/common/commonStates";
import {ISelectValue} from "./main/components/common/Select.component";
import {allFlashReportsSummariesInner} from "./main/components/flash-report/AllFlashReportsReducer";
import {
    allHoldingsSelectorInner,
    holdingsByPlanSelectorInner
} from "./main/components/investments/holdings/HoldingsReducer";
import {allManagersInner} from "./main/components/manager/AllManagersReducer";
import {allMeetingNotesInner} from "./main/components/meeting-note/AllMeetingNotesReducer";
import {
    getPortfolioProductSummariesInner,
    getProductUniverseSummariesInner,
    selectProductSummariesBasedOnEditModeInner,
    shareClassesInner,
} from "./main/components/product/AllProductsReducer";
import {
    currentProductPageTabInner,
    mostRecentInBriefInner,
    mostRecentInBriefLoadingInner,
    mostRecentInReportsInner,
    mostRecentInTotalInner,
    mostRecentInTotalLoadingInner,
    tickersInner,
} from "./main/components/product/productReducer";
import {isEditingPlanPortfolioInner} from "./main/components/research/edit-portfolio/editPortfolioReducer";
import {oddPreviewsInner} from "./main/components/research/productStoreReducer";
import {
    clientPerformanceReportingAccessInner,
    clientReportingEnabledInner,
    clientUploadsEnabledInner,
    emailEnabledInner,
    hasClientReportsInner,
    hasClientResearchEnabledInner,
    hasHoldingsInner,
    hasOddPowerBiEnabledInner,
    hasPowerbiReportAdminInner,
    hasCprSharedMediaAdminInner,
    hasProductsInner,
    hasResearchAccessInner,
    hasThirdPartyAccessInner,
    insightsEnabledInner,
    investmentsEnabledInner,
    oddIqEnabledInner,
    oddReportsEnabledInner,
    performanceReportingEnabledInner,
    cprPortfolioOverviewEnabledInner,
    planCountryNameInner,
    planLanguageIdInner,
    planSettingsLoadedInner,
    showFactSheetsInner,
    sharedDocumentsEnabledInner,
    hasAonTrustCompanyInner,
    hasCalendarEnabledForConsultantsInner,
    hasCalendarEnabledForClientsInner,
    planTypeIdSelectorInner,
    hasPortfolioAccessInner,
    hasClientPortfolioAccessInner,
    hasDynamicResearchEnabledInner,
    hasAllAccessEnabledInner,
} from "./main/components/root/PlanSettings.reducer";
import {
    currentPlanInner,
    currentUserEmailInner,
    hasPlansInner,
    sessionPlansInner,
    userTypeInner
} from "./main/components/session/SessionReducer";
import {
    clientUsersInner,
    sharedDocumentsSelectorInner,
    usersInSessionPlansSelectorInner
} from "./main/components/shared-documents/SharedDocuments.reducer";
import {
    capitalMarketAssumptionsInner,
    marketBackgroundsInner,
    mediumTermViewsInner,
    quarterlyInvestmentOutlookInner,
    whitePapersInner,
} from "./main/components/thought-leadership/ThoughtLeadershipReducer";
import {ClientsAndPlans, IPlanInfo} from "./main/model/ClientsAndPlans.model";
import {ClientUser} from "./main/model/ClientUser.model";
import {FlashReportSummary} from "./main/model/FlashReportSummary.model";
import {ManagerResponse} from "./main/model/manager/ManagerResponse.model";
import {MeetingNoteSummary} from "./main/model/MeetingNoteSummary.model";
import {IInReportGroup, InReportSummary} from "./main/model/product/inreports/InReportSummary.model";
import {Product} from "./main/model/product/Product.model";
import {ProductSummary} from "./main/model/product/ProductSummary";
import {ProductTicker} from "./main/model/product/ProductTicker";
import {CapitalMarketAssumption} from "./main/model/thought-leadership/CapitalMarketAssumption";
import {MediumTermView} from "./main/model/thought-leadership/MediumTermView.model";
import {IMarketOutlookDocument} from "./main/model/thought-leadership/MarketOutlookDocument.model";
import {WhitePaper} from "./main/model/thought-leadership/WhitePaper.model";
import {IUserInfo, UserTypeEnum} from "./main/model/UserInfo.model";
import {SharedDocument} from "./main/model/SharedDocument.model";
import {
    powerBiClientReportsSelectorInner,
    powerBiReportCategorySelectorInner, powerBiReportExportPeriodSelectorInner, powerBiReportExportSelectorInner
} from "./main/components/power-bi-reporting/PowerBiReporting.reducer";
import {ICalendarMeeting} from "./main/api/CalendarApi";
import {IReportExportInfo} from "./main/api/PowerBiReportingApi";
import {
    calendarCurrentYearSelectorInner,
    calendarMeetingsSelectorInner
} from "./main/components/calendar/Calendar.reducer";
import {ClientReport} from "./main/model/ClientReport.model";
import {IFundingLevel} from "./main/api/CprPortfolioApi";
import {fundingLevelSelectorInner} from "./main/components/home-page/CprPortfolioReducer";
import {IApplicationRootState} from "./applicationState";
import {ProductPageTab} from "./main/components/product/ProductPage";

export function isEditingPlanPortfolio(state: IApplicationRootState): boolean {
    return isEditingPlanPortfolioInner(state.portfolioAdminState);
}

export function selectProductSummariesBasedOnEditMode(state: IApplicationRootState):
    RequestState<Map<number, ProductSummary>> {

    const isEditing = isEditingPlanPortfolio(state);
    return selectProductSummariesBasedOnEditModeInner(state.allProductsStore!, isEditing);
}

export function currentPlan(state: IApplicationRootState): IPlanInfo {
    return currentPlanInner(state.session);
}

export function currentUserEmail(state: IApplicationRootState): string {
    return currentUserEmailInner(state.session);
}

export function sessionPlans(state: IApplicationRootState): IPlanInfo[] {
    return sessionPlansInner(state.session);
}

export function hasPlans(state: IApplicationRootState): boolean {
    return hasPlansInner(state.session);
}

export function userType(state: IApplicationRootState): UserTypeEnum {
    return userTypeInner(state.session);
}

export function mostRecentInBrief(state: IApplicationRootState): InReportSummary | undefined {
    return mostRecentInBriefInner(state.productPageState);
}

export function mostRecentInTotal(state: IApplicationRootState): InReportSummary | undefined {
    return mostRecentInTotalInner(state.productPageState);
}

export function mostRecentInReports(state: IApplicationRootState): RequestState<IInReportGroup> {
    return mostRecentInReportsInner(state.productPageState);
}

export function mostRecentInBriefLoading(state: IApplicationRootState): boolean | undefined {
    return mostRecentInBriefLoadingInner(state.productPageState);
}

export function mostRecentInTotalLoading(state: IApplicationRootState): boolean | undefined {
    return mostRecentInTotalLoadingInner(state.productPageState);
}

export function tickers(state: IApplicationRootState): RequestState<ProductTicker[]> {
    return tickersInner(state.productPageState);
}

export function shareClasses(state: IApplicationRootState): RequestState<ISelectValue[]> {
    return shareClassesInner(state.allProductsStore!);
}

export function relatedPlans(state: IApplicationRootState): RequestState<IPlanInfo[]> {
    return relatedPlansInner(state.currentClient!);
}

export function hasResearchAccess(state: IApplicationRootState): boolean {
    return hasResearchAccessInner(state.planSettings);
}

export function hasPortfolioAccess(state: IApplicationRootState): boolean {
    return hasPortfolioAccessInner(state.planSettings);
}

export function hasClientPortfolioAccess(state: IApplicationRootState): boolean {
    return hasClientPortfolioAccessInner(state.planSettings);
}

export function hasDynamicResearchAccess(state: IApplicationRootState): boolean {
    return hasDynamicResearchEnabledInner(state.planSettings);
}

export function hasAllAccessEnabled(state: IApplicationRootState): boolean {
    return hasAllAccessEnabledInner(state.planSettings);
}

export function hasThirdPartyAccess(state: IApplicationRootState): boolean {
    return hasThirdPartyAccessInner(state.planSettings);
}

export function hasPowerbiReportAdmin(state: IApplicationRootState): boolean {
    return hasPowerbiReportAdminInner(state.planSettings);
}

export function hasCprSharedMediaAdmin(state: IApplicationRootState): boolean {
    return hasCprSharedMediaAdminInner(state.planSettings);
}

export function hasAonTrustCompany(state: IApplicationRootState): boolean {
    return hasAonTrustCompanyInner(state.planSettings);
}

export function hasCalendarEnabledForConsultants(state: IApplicationRootState): boolean {
    return hasCalendarEnabledForConsultantsInner(state.planSettings);
}

export function hasCalendarEnabledForClients(state: IApplicationRootState): boolean {
    return hasCalendarEnabledForClientsInner(state.planSettings);
}
export function hasOddPowerBiEnabled(state: IApplicationRootState): boolean {
    return hasOddPowerBiEnabledInner(state.planSettings);
}

export function hasProducts(state: IApplicationRootState): boolean {
    return hasProductsInner(state.planSettings);
}

export function planSettingsAreAvailable(state: IApplicationRootState): boolean {
    return planSettingsLoadedInner(state.planSettings);
}

export function hasClientResearchEnabled(state: IApplicationRootState): boolean {
    return hasClientResearchEnabledInner(state.planSettings);
}

export function planCountryName(state: IApplicationRootState): string {
    return planCountryNameInner(state.planSettings);
}

export function showFactSheets(state: IApplicationRootState): boolean {
    return showFactSheetsInner(state.planSettings);
}

export function emailEnabled(state: IApplicationRootState): boolean {
    return emailEnabledInner(state.planSettings);
}

export function hasHoldings(state: IApplicationRootState): boolean {
    return hasHoldingsInner(state.planSettings);
}

export function hasClientReports(state: IApplicationRootState): boolean {
    return hasClientReportsInner(state.planSettings);
}

export function sharedDocumentsEnabled(state: IApplicationRootState): boolean {
    return sharedDocumentsEnabledInner(state.planSettings);
}

export function clientUploadsEnabled(state: IApplicationRootState): boolean {
    return clientUploadsEnabledInner(state.planSettings);
}

export function oddIqEnabled(state: IApplicationRootState): boolean {
    return oddIqEnabledInner(state.planSettings);
}

export function insightsEnabled(state: IApplicationRootState): boolean {
    return insightsEnabledInner(state.planSettings);
}

export function oddReportsEnabled(state: IApplicationRootState): boolean {
    return oddReportsEnabledInner(state.planSettings);
}

export function performanceReportingEnabled(state: IApplicationRootState): boolean {
    return performanceReportingEnabledInner(state.planSettings);
}

export function cprPortfolioOverviewEnabled(state: IApplicationRootState): boolean {
    return cprPortfolioOverviewEnabledInner(state.planSettings);
}

export function clientReportingEnabled(state: IApplicationRootState): boolean {
    return clientReportingEnabledInner(state.planSettings);
}

export function clientPerformanceReportingAccess(state: IApplicationRootState): boolean {
    return clientPerformanceReportingAccessInner(state.planSettings);
}

export function planLanguageId(state: IApplicationRootState): number {
    return planLanguageIdInner(state.planSettings);
}
export function planTypeIdSelector(state: IApplicationRootState): number | null {
    return planTypeIdSelectorInner(state.planSettings);
}

export const adminUsersInfoStateSelector = (state: IApplicationRootState): RequestState<IUserInfo[]> => {
    return adminUsersInfoStateSelectorInner(state.adminState);
};

export const adminClientsInfoStateSelector = (state: IApplicationRootState): RequestState<ClientsAndPlans> => {
    return adminClientsInfoStateSelectorInner(state.adminState);
};

export function clientUsers(state: IApplicationRootState): RequestState<ClientUser[]> {
    return clientUsersInner(state.sharedDocumentsRootState!);
}

export function usersInSessionPlansSelector(state: IApplicationRootState): RequestState<ClientUser[]> {
    return usersInSessionPlansSelectorInner(state.sharedDocumentsRootState!);
}

export const allManagersSelector = (state: IApplicationRootState): RequestState<Map<number, ManagerResponse>> => {
    return allManagersInner(state.allManagersStore!);
};

export const allMeetingNotesSelector =
    (state: IApplicationRootState): RequestState<Map<number, MeetingNoteSummary>> => {
        return allMeetingNotesInner(state.allMeetingNotesStore!);
    };

export function sharedDocumentsSelector(state: IApplicationRootState): RequestState<SharedDocument[]> {
    return sharedDocumentsSelectorInner(state.sharedDocumentsRootState);
}

export function productUniverseSummariesSelector(state: IApplicationRootState) {
    return getProductUniverseSummariesInner(state.allProductsStore!);
}

export function portfolioProductSummariesSelector(state: IApplicationRootState) {
    return getPortfolioProductSummariesInner(state.allProductsStore!);
}

export const allFlashReportsSummariesSelector =
    (state: IApplicationRootState): RequestState<Map<number, FlashReportSummary>> => {
        return allFlashReportsSummariesInner(state.allFlashReportsStore!);
    };

export const capitalMarketAssumptionsSelector =
    (state: IApplicationRootState): RequestState<Map<number, CapitalMarketAssumption>> => {
        return capitalMarketAssumptionsInner(state.thoughtLeadership!);
    };

export const mediumTermViewsSelector = (state: IApplicationRootState): RequestState<MediumTermView[]> => {
    return mediumTermViewsInner(state.thoughtLeadership!);
};

export const whitePapersSelector = (state: IApplicationRootState): RequestState<WhitePaper[]> => {
    return whitePapersInner(state.thoughtLeadership!);
};

export const holdingsSelector = (state: IApplicationRootState): RequestState<IHolding[]> => {
    return holdingsByPlanSelectorInner(state.holdingsState!);
};

export const allHoldingsSelector = (state: IApplicationRootState): RequestState<IHolding[]> => {
    return allHoldingsSelectorInner(state.holdingsState!);
};

export const oddPreviews =
    (state: IApplicationRootState): Map<number, Product> => {
        return oddPreviewsInner(state.productStore);
    };

export const quarterlyInvestmentOutlookSelector = (state: IApplicationRootState):
    RequestState<IMarketOutlookDocument[]> => {
    return quarterlyInvestmentOutlookInner(state.thoughtLeadership);
};

export const marketBackgroundsSelector = (state: IApplicationRootState):
    RequestState<IMarketOutlookDocument[]> => {
    return marketBackgroundsInner(state.thoughtLeadership);
};

export function investmentsEnabled(state: IApplicationRootState): boolean {
    return investmentsEnabledInner(state.planSettings);
}

export function powerBiClientReportsSelector(state: IApplicationRootState): RequestState<ClientReport[]> {
    return powerBiClientReportsSelectorInner(state.powerBiReportingStore);
}

export function powerBiReportCategorySelector(state: IApplicationRootState): string | undefined {
    return powerBiReportCategorySelectorInner(state.powerBiReportingStore);
}

export function calendarMeetingsSelector(state: IApplicationRootState): RequestState<Map<string, ICalendarMeeting>> {
    return calendarMeetingsSelectorInner(state.calendarStore);
}

export function calendarCurrentYearSelector(state: IApplicationRootState): number | undefined {
    return calendarCurrentYearSelectorInner(state.calendarStore);
}

export function currentProductPageTab(state: IApplicationRootState): ProductPageTab | undefined {
    return currentProductPageTabInner(state.productPageState);
}

export function powerBiReportExportSelector(state: IApplicationRootState): RequestState<IReportExportInfo> {
    return powerBiReportExportSelectorInner(state.powerBiReportingStore);
}

export function powerBiReportExportPeriodSelector(state: IApplicationRootState): number | undefined {
    return powerBiReportExportPeriodSelectorInner(state.powerBiReportingStore);
}

export function fundingLevelSelector(state: IApplicationRootState): RequestState<IFundingLevel | undefined> {
    return fundingLevelSelectorInner(state.cprPortfolioStore);
}