import {Map} from "immutable";
import * as React from "react";
import {connect} from "react-redux";
import {RouteComponentProps, withRouter} from "react-router";
import {
    hasHoldings,
    investmentsEnabled,
    isEditingPlanPortfolio,
    portfolioProductSummariesSelector,
    productUniverseSummariesSelector,
    userType,
} from "../../../mainReducerMapSelectors";
import {navigateTo} from "../../../navigateTo";
import {getBrowserLocationPath} from "../../api/BrowserWrapper";
import {ProductSummary} from "../../model/product/ProductSummary";
import {ResearchSearchItem} from "../../model/Search.model";
import {getSearchItemsFromProducts} from "../../utils/researchUtil";
import {hasConsultantLevelAccess} from "../../utils/sessionUtil";
import {
    allResearchItems,
    investmentsItem,
    IResearchNavigationMenuItems,
    researchItem,
    ResearchMenuItems,
    UPDATES_PATHS,
    updatesItem,
} from "../base/NavItems";
import SubNav from "../base/SubNav.component";
import {getSuccessData} from "../common/commonStates";
import {allProductsRequestPair} from "../common/RequesterPairs";
import {UserTypeEnum} from "../../model/UserInfo.model";
import {multipleApiRequesterWrapper} from "../common/MultipleApiRequesterWrapper";
import {IApplicationRootState} from "../../../applicationState";
import {showInvestments} from "../../utils/navigationUtils";

interface IResearchNavPropsFromState {
    editingPlanPortfolio: boolean;
    hasHoldings: boolean;
    productUniverseSummaries: Map<number, ProductSummary>;
    portfolioProductSummaries: Map<number, ProductSummary>;
    userType: UserTypeEnum;
    investmentsEnabled: boolean;
}

export type ResearchNavProps = IResearchNavPropsFromState & RouteComponentProps;

export const researchDescription = "Explore Aon's comprehensive views on investment managers and strategies. " +
    "Research and gain insights into the various investment opportunities available.";

export const ResearchNavComponent: React.FunctionComponent<ResearchNavProps> = (props) => {
    const menuItems = getNavigationMenuItems();

    function getResearchMenuItemFromUrl() {
        const maybeMenuItem =
            allResearchItems.find((item) => getBrowserLocationPath().includes(item.targetUrl));
        return maybeMenuItem ? maybeMenuItem.id : researchItem.id;
    }

    function isStrategyUpdateUrl() {
        return !!UPDATES_PATHS.find((item) => getBrowserLocationPath().includes(item));
    }

    function getNavigationMenuItems(): IResearchNavigationMenuItems[] {
        const maybeInvestmentsItem = showInvestments(props.hasHoldings, props.userType, props.investmentsEnabled)
            ? investmentsItem
            : undefined;

        return [maybeInvestmentsItem, researchItem, updatesItem]
            .filter((item) => !!item) as IResearchNavigationMenuItems[];
    }

    function isSearchEnabled() {
        const isNewResearchPage = getBrowserLocationPath().includes("/new-research");
        return isNewResearchPage
            ? props.editingPlanPortfolio && getSelectedMenuItem() === ResearchMenuItems.RESEARCH_ID.toString()
            : getSelectedMenuItem() === ResearchMenuItems.RESEARCH_ID.toString();
    }

    function getSelectedMenuItem() {
        return isStrategyUpdateUrl()
            ? ResearchMenuItems.STRATEGY_UPDATES_ID
            : getResearchMenuItemFromUrl();
    }

    function onItemSelected(selectedMenuItem: string) {
        navigateTo(menuItems.find((item) => item.id === selectedMenuItem)!.targetUrl);
    }

    function getSearchItems(): ResearchSearchItem[] {
        const productSummaries = (hasConsultantLevelAccess() && props.editingPlanPortfolio)
            ? props.productUniverseSummaries.valueSeq().toArray()
            : props.portfolioProductSummaries.valueSeq().toArray();
        return getSearchItemsFromProducts(productSummaries);
    }

    const renderResearchHeader = () => {
        const isNewResearchPage = getBrowserLocationPath().includes("/new-research");
        return isNewResearchPage
            ? <div className="sub-nav__research-header-container" data-testid="sub-nav__research-header-container">
                <div className="sub-nav__research-header-title">Research</div>
                <div className="sub-nav__research-header-description">{researchDescription}</div>
            </div>
            : null;
    };

    return <>
        <SubNav
            menuItems={menuItems}
            searchEnabled={isSearchEnabled()}
            onItemSelected={onItemSelected}
            selectedMenuItem={getSelectedMenuItem()}
            searchItems={getSearchItems()}
        />
        {!props.editingPlanPortfolio && renderResearchHeader()}
    </>;
};

export const mapStateToProps = (state: IApplicationRootState): IResearchNavPropsFromState => {
    return {
        editingPlanPortfolio: isEditingPlanPortfolio(state),
        hasHoldings: hasHoldings(state),
        productUniverseSummaries: getSuccessData(productUniverseSummariesSelector(state))!,
        portfolioProductSummaries: getSuccessData(portfolioProductSummariesSelector(state))!,
        userType: userType(state),
        investmentsEnabled: investmentsEnabled(state),
    };
};

const connectedComponent = withRouter(connect<IResearchNavPropsFromState>(mapStateToProps)(ResearchNavComponent));

export default multipleApiRequesterWrapper(
    connectedComponent,
    [
        allProductsRequestPair(),
    ],
    undefined,
    null,
);
