import * as React from "react";
import {ISelectValue} from "../common/Select.component";
import {Label, Legend, Pie, PieChart, ResponsiveContainer, Tooltip} from "recharts";
import {calculateColorAndOpacity, getTotalPlanNode, portfolioTreeFilteredByDate} from "./portfolioUtil";
import {useEffect, useState} from "react";
import {IPlanTreeNodeFlat} from "../../model/Portfolio.model";
import {
    getQuarterAndYearByDate, IQuarterAndYear,
    parseDate
} from "../../utils/dateUtil";
import {formatCurrency} from "../../utils/numberUtil";

export interface IAssetAllocationDonutChartProps {
    dataNodes: IPlanTreeNodeFlat[],
    selectedDate?: ISelectValue
}

interface IPieChartData {
    name: string,
    value: number,
    fill: string,
    opacity: number
}

export const AssetAllocationDonutChart: React.FunctionComponent<IAssetAllocationDonutChartProps> = (props) => {
    const quarterAndYear = getQuarterAndYearByDate(parseDate(props.selectedDate?.id as string));
    const [nodes, setNodes] = useState<IPieChartData[]>([]);

    useEffect(() => {
        const date = (props.selectedDate?.id as string);
        setNodes(portfolioTreeFilteredByDate(props.dataNodes, date)
            .map((node, index) => {
                const colorAndOpacity = calculateColorAndOpacity(index);
                return {
                    name: `${node.name}, ${node.marketValuePctOfTotal.toFixed(2)}%`,
                    value: node.marketValue,
                    fill: colorAndOpacity.fill,
                    opacity: colorAndOpacity.opacity,
                };
            })
        );
    }, [props]);

    const renderMarketValue = () => {
        const totalNode = getTotalPlanNode(props.dataNodes, props.selectedDate?.id as string);
        return <div className="asset-allocation-donut-chart__market-value-container">
            <div>{totalNode === undefined ? "" : formatCurrency(totalNode!.marketValue)}</div>
            {totalNode && <div className="asset-allocation-donut-chart__market-value-text">Market Value</div>}
        </div>;
    };

    const renderAssetAllocationDonutChart = () => {
        return <div className="asset-allocation-donut-chart__container"
                    data-testid="asset-allocation-donut-chart__container">
            {renderMarketValue()}
            <ResponsiveContainer width="100%" height={300}>
                <PieChartComponent nodes={nodes} quarterAndYear={quarterAndYear} />
            </ResponsiveContainer>
        </div>;
    };

    return renderAssetAllocationDonutChart();
};

interface IPieChartComponentProps {
    nodes: IPieChartData[],
    quarterAndYear: IQuarterAndYear
}

const PieChartComponent: React.FunctionComponent<IPieChartComponentProps> = (props) => {
    const customLegend = (props: any) => {
        const {payload} = props;

        return (
            <ul style={{textAlign: "left"}}>
                {
                    payload.map((entry: any, index: number) => (
                        <li key={`item-${index}`} style={{
                            color: calculateColorAndOpacity(index).fill,
                            opacity: calculateColorAndOpacity(index).opacity,
                            listStylePosition: "outside",
                        }}>
                            {`${entry.value}`}
                        </li>
                    ))
                }
            </ul>
        );
    };

    const customTooltip = (toolTipProps: any) => {
        if (toolTipProps.active && toolTipProps.payload && toolTipProps.payload.length) {
            return (
                <div className="custom-tooltip">
                    <div className="label">{toolTipProps.payload[0].payload.name.split(",")[0]}</div>
                    <div className="value">{formatCurrency(toolTipProps.payload[0].value)}</div>
                </div>
            );
        }
        return null;
    };

    return <PieChart width={420} height={268}>
        <Pie
            dataKey="value"
            data={props.nodes}
            cx={120}
            cy={120}
            innerRadius={90}
            outerRadius={120}
            legendType={"circle"}
            inactiveShape={{style: {opacity: 0.3}}}
            activeShape={{style: {opacity: 1}}}

        >
            <Label width={30} position="center" className="quarter-label">
                {`${props.quarterAndYear.quarter} ${props.quarterAndYear.year}`}
            </Label>
        </Pie>
        <Tooltip content={customTooltip}  position={{ x: 0, y: 215 }}/>
        <Legend
            width={403}
            wrapperStyle={{
                top: 10,
                left: 255,
                lineHeight: "30px",
                textAlign: "left",
            }}
            content={customLegend}
        />
    </PieChart>;
};