import {IPlanTreeNode, IPlanTreeNodeFlat} from "../../model/Portfolio.model";

export enum DonutChartColors {
    Steelblue = "#0084BB",
    Purple = "#6E027F",
    Cadetblue = "#29B0C3",
    Midnightblue = "#101E7F",
    Mediumvioletred = "#A70070",
    Mediumturquoise = "#73E2D8",
    Gold = "#FFC300",
    Darkseagreen = "#97ab72",
    Darkslateblue = "#581845",
    Lightseagreen = "#58ada6",
    Darkslategray = "#193f63",
    Chocolate = "#d47502"
}

export const getTotalPlanNode = (flatNodes: IPlanTreeNodeFlat[], date: string | undefined = undefined): IPlanTreeNode | undefined => {
    const nodes = date ? flatNodes.filter(node => node.date === date) : flatNodes;
    return nodes.find(node => node.parentId === null);
};

export const portfolioTreeFilteredByDate = (flatNodes: IPlanTreeNodeFlat[], date: string): IPlanTreeNode[] => {
    return buildPortfolioTree(flatNodes.filter(node => node.date === date));
};

export const buildPortfolioTree = (flatNodes: IPlanTreeNodeFlat[]): IPlanTreeNode[] => {
    if (flatNodes.length === 0) {
        return [];
    }
    const totalPlanNode = getTotalPlanNode(flatNodes);
    return totalPlanNode ? getChildNodes(flatNodes, totalPlanNode.id) : [];
};

const getChildNodes = (flatNodes: IPlanTreeNodeFlat[], parentId: number): IPlanTreeNode[] => {
    return flatNodes
        .filter(it => it.parentId === parentId)
        .sort((a, b) => a.sortOrder - b.sortOrder)
        .map(node => ({
            id: node.id,
            name: node.name,
            sortOrder: node.sortOrder,
            accountType: node.accountType,
            marketValue: node.marketValue,
            marketValuePctOfTotal: node.marketValuePctOfTotal,
            marketValuePctOfParent: node.marketValuePctOfParent,
            backstopId: node.backstopId,
            nodes: node.accountType === 1 ? null : getChildNodes(flatNodes, node.id),

        }));
};

export const calculateColorAndOpacity = (index: number) => {
    const colors = [
        DonutChartColors.Steelblue,
        DonutChartColors.Purple,
        DonutChartColors.Cadetblue,
        DonutChartColors.Midnightblue,
        DonutChartColors.Mediumvioletred,
        DonutChartColors.Mediumturquoise,
        DonutChartColors.Gold,
        DonutChartColors.Darkseagreen,
        DonutChartColors.Darkslateblue,
        DonutChartColors.Lightseagreen,
        DonutChartColors.Darkslategray,
        DonutChartColors.Chocolate,
    ];

    const rounds = Math.trunc(index / colors.length);
    const calculatedIndex = index % colors.length;

    return {fill: colors[calculatedIndex], opacity: 1 / Math.pow(2, rounds)};
};