import {Map} from "immutable";
import * as React from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {bindActionCreators, Dispatch} from "redux";
import {
    allFlashReportsSummariesSelector,
    allMeetingNotesSelector,
    isEditingPlanPortfolio,
    oddIqEnabled,
} from "../../../mainReducerMapSelectors";
import {navigateTo} from "../../../navigateTo";
import {IPlanInfo} from "../../model/ClientsAndPlans.model";
import {ComponentRating} from "../../model/ComponentRating";
import {FlashReportSummary} from "../../model/FlashReportSummary.model";
import {IStrategyUpdate} from "../../model/IStrategyUpdate.model";
import {MeetingNoteSummary} from "../../model/MeetingNoteSummary.model";
import {Product} from "../../model/product/Product.model";
import {prominentButtonStyle, removeFromPortfolioButtonStyle} from "../common/buttonStyles";
import {RaisedButton} from "../common/RaisedButton";
import {now} from "../../utils/dateProvider";
import {compareDates, formatShortDate} from "../../utils/dateUtil";
import {throwErrorOnNullOrUndefined} from "../../utils/errorUtil";
import {
    getEsgMovementIconClass,
    getEsgRatingIconClass,
    getEsgRatingText,
    getMovementIconClass,
    getRatingIconClass,
    getRatingText
} from "../../utils/ratingFormatUtil";
import {Movement, NOT_RATED} from "../../utils/ratingConstants";
import {Brackets, BracketsBox} from "../base/Brackets.component";
import {getSuccessData} from "../common/commonStates";
import {allFlashReportsSummariesRequestPair, allMeetingsNotesRequestPair} from "../common/RequesterPairs";
import productActions from "../product/productActions";
import {RatingStatus} from "../product/rating/RatingStatus.component";
import {multipleApiRequesterWrapper} from "../common/MultipleApiRequesterWrapper";
import {FormattedMessage} from "react-intl";
import {getTranslation} from "../../utils/translationUtil";
import moment = require("moment");
import {IApplicationRootState} from "../../../applicationState";

export interface IProductPreviewPropsFromParent {
    product: Product;
}

export interface IProductPreviewPropsFromStore {
    editingPlanPortfolio: boolean;
    currentPlan: IPlanInfo;
    allFlashReportSummaries: Map<number, FlashReportSummary>;
    allMeetingNoteSummaries: Map<number, MeetingNoteSummary>;
    oddIqEnabled: boolean;
}

export interface IProductPreviewPropsFromActions {
    actions: {
        removeProductFromPortfolio: (backstopId: number) => void;
    };
}

export type ProductPreviewProps = IProductPreviewPropsFromParent
    & IProductPreviewPropsFromStore
    & IProductPreviewPropsFromActions;

export class ProductPreviewComponent extends React.Component<ProductPreviewProps> {

    private static isWithinSixMonths(date: Date | null) {
        if (date === null) {
            return false;
        }

        const cutoffDate = moment(now()).subtract(6, "months").toDate();
        return date.getTime() >= cutoffDate.getTime();
    }

    private static getRatingIconClass(currentRating: string | null, movement: Movement): string {
        return `product__rating-subcomponent__rating-icon rating-icon 
        ${getRatingIconClass(currentRating)} ${getMovementIconClass(movement)}`;
    }

    private static getEsgRatingIconClass(currentRating: string | null, movement: Movement): string {
        return `product__rating-subcomponent__rating-icon rating-icon 
        ${getEsgRatingIconClass(currentRating)} ${getEsgMovementIconClass(currentRating, movement)}`;
    }

    // noinspection JSMethodCanBeStatic
    private renderBox(title: string, componentRating: ComponentRating) {
        return <BracketsBox className="product__rating-subcomponent">
            <span className="product__rating-subcomponent__title body-16">
                {title.replace(/\s+/g, "\xa0")}
            </span>
            <div className={ProductPreviewComponent
                .getRatingIconClass(componentRating.rating, componentRating.movement)}/>
            <div className="product__rating-subcomponent__rating date-14">
                {getRatingText(componentRating.rating)}
            </div>
        </BracketsBox>;
    }

    // noinspection JSMethodCanBeStatic
    private renderEsgBox(title: string, componentRating: ComponentRating) {
        return <BracketsBox className="product__rating-subcomponent">
            <span className="product__rating-subcomponent__title body-16">
                {title.replace(/\s+/g, "\xa0")}
            </span>
            <div className={ProductPreviewComponent
                .getEsgRatingIconClass(componentRating.rating, componentRating.movement)}/>
            <div className="product__rating-subcomponent__rating date-14">
                {getEsgRatingText(componentRating.rating)}
            </div>
        </BracketsBox>;
    }

    private renderEsg(esgRating: ComponentRating | null) {
        return esgRating
        ? this.renderEsgBox(
                    getTranslation( "product-sub-component.ESG","ESG"),
                    esgRating)
        :null;
    }

    private static renderStrategyDetailsButton(backstopId: number) {
        return <RaisedButton key="strategy-details"
                             className="strategy-details-button"
                             style={prominentButtonStyle}
                             primary={true}
                             onClick={() => navigateTo(`/products/${backstopId}`)}>
            <FormattedMessage
                id="research-product.strategy-details"
                defaultMessage="Strategy Details"
            />
        </RaisedButton>;
    }

    private static byMostRecent(update1: IStrategyUpdate, update2: IStrategyUpdate) {
        const defaultDate = new Date(0, 0, 0);
        return compareDates(update1.date || defaultDate, update2.date || defaultDate);
    }

    public render() {
        const {product} = this.props;
        const {
            backstopId,
            businessRating,
            staffRating,
            processRating,
            riskRating,
            performanceAnalysisRating,
            tncRating,
            currentRating,
            previousRating,
            esgRating,
        } = (product);

        const ratingStatusProps = {
            previousRating: previousRating && previousRating.value,
            previousRatingDate: previousRating && previousRating.date,
            currentRating: currentRating.value,
            currentRatingDate: currentRating.date,
            ratingType: currentRating.ratingType,
            isOperationalDueDiligenceRating: false,
            hasOddDetails: false,
            productBackstopId: backstopId,
            showTooltip: false,
            oddIqEnabled: this.props.oddIqEnabled,
        };

        return (
            <div className="product-card__content product" data-testid="product-card__content">
                <div className="product__header">
                    <div className="product__header__info-stack">
                        <RatingStatus {...ratingStatusProps}/>
                    </div>
                    <div className="product__header__buttons">
                        {this.renderRemoveFromPortfolioButton()}
                        {ProductPreviewComponent.renderStrategyDetailsButton(backstopId)}
                    </div>
                </div>
                <div className="product__rating-subcomponents">
                    <Brackets>
                        {this.renderBox(
                            getTranslation( "product-sub-component.Business", "Business"),
                            businessRating)}
                        {this.renderBox(
                            getTranslation( "product-sub-component.Investment Staff","Investment Staff"),
                            staffRating)}
                        {this.renderBox(
                            getTranslation( "product-sub-component.Investment Process","Investment Process"),
                            processRating)}
                        {this.renderBox(
                            getTranslation( "product-sub-component.Risk Management","Risk Management"),
                            riskRating)}
                        {this.renderBox(
                            getTranslation( "product-sub-component.Performance","Performance"),
                            performanceAnalysisRating)}
                        {this.renderBox(
                            getTranslation("product-sub-component.Terms & Conditions","Terms & Conditions"),
                            tncRating)}
                        {this.renderEsg(esgRating)}
                    </Brackets>
                </div>
                {this.renderExtraData(this.props.product)}
            </div>
        );
    }

    private renderRemoveFromPortfolioButton() {
        if (!this.props.editingPlanPortfolio) {
            return;
        }

        return <RaisedButton key="remove-from-portfolio"
                             className="remove-from-portfolio-button"
                             style={removeFromPortfolioButtonStyle}
                             primary={true}
                             onClick={() => {
                 this.props.actions.removeProductFromPortfolio(
                     this.props.product.backstopId,
                 );
             }}>
            Remove from Portfolio
        </RaisedButton>;
    }

    private renderExtraData(product: Product) {
        return (
            <div className="product__extra-data" data-testid="product__extra-data">
                <div className="product__recent-updates">
                    <span className="title-small">
                        <FormattedMessage
                            id="research-product.recent-strategy-updates"
                            defaultMessage="Recent Strategy Updates"
                        />
                    </span>
                    <div className="document-list__document-separator product__subheader-spacer">
                        <div className="spacer-dash--small"/>
                    </div>
                    {this.renderRecentUpdates()}
                </div>
                <div className="product__odd-info">
                    <span className="title-small">
                         <FormattedMessage
                             id="research-product.odd-label"
                             defaultMessage="Operational Due Diligence"
                         />
                    </span>
                    <div className="document-list__document-separator product__subheader-spacer">
                        <div className="spacer-dash--small"/>
                    </div>
                    <RatingStatus {...{
                        previousRating: product.oppDueDiligenceRatings!.ratingPrevious,
                        previousRatingDate: product.oppDueDiligenceRatings!.previousRatingEffectiveDate,
                        currentRating: product.oppDueDiligenceRatings!.rating,
                        currentRatingDate: product.oppDueDiligenceRatings!.ratingEffectiveDate,
                        isSmallText: true,
                        ratingType: product.currentRating.ratingType,
                        isOperationalDueDiligenceRating: true,
                        hasOddDetails: false,
                        productBackstopId: product.backstopId,
                        showTooltip: false,
                        oddIqEnabled: this.props.oddIqEnabled,
                    }}/>
                </div>
            </div>);
    }

    private renderRecentUpdates() {
        const recentUpdates = this.getRecentUpdates()
            .sort(ProductPreviewComponent.byMostRecent)
            .slice(0, 3)
            .filter((it) => ProductPreviewComponent.isWithinSixMonths(it.date));

        if (recentUpdates.length === 0 || this.props.product.currentRating.value === NOT_RATED) {
            return <div className="product__recent-updates__no-updates">
                <FormattedMessage
                    id="research-product.no-updates"
                    defaultMessage="There are no updates at this time."
                />
            </div>;
        }

        return recentUpdates
            .map((it) => {
                return <div key={it.backstopId} className="product__recent-updates__list-item">
                    <Link className="product__recent-updates__link"
                          to={this.getRecentUpdateLink(it)}>
                        <div className="product__recent-updates__link-text link-14">{it.title}</div>
                    </Link>
                    <div className="product__recent-updates__date date-12">
                        {it.date && formatShortDate(it.date)}
                    </div>
                </div>;
            });
    }

    private getRecentUpdates() {
        const meetingNotes = (this.getMeetingNoteSummaries());
        const flashReports = this.getFlashReportSummaries();
        return [...meetingNotes, ...flashReports];
    }

    private getMeetingNoteSummaries() {
        return this.props.allMeetingNoteSummaries.valueSeq().toArray()
            .filter(this.isAttachedToProduct);
    }

    private getFlashReportSummaries() {
        return this.props.allFlashReportSummaries.valueSeq().toArray()
            .filter(this.isAttachedToProduct);
    }

    private isAttachedToProduct = (strategyUpdate: IStrategyUpdate) => {
        return strategyUpdate.productIds.includes(this.props.product.backstopId);
    };

    private getRecentUpdateLink(item: FlashReportSummary | MeetingNoteSummary): string {
        if (item instanceof FlashReportSummary) {
            return `/${item.urlPrefix}/${item.backstopId}`;
        } else {
            return `/products/${this.props.product.backstopId}/${item.urlPrefix}/${item.backstopId}`;
        }
    }

}

export const mapStateToProps = (state: IApplicationRootState): IProductPreviewPropsFromStore => {
    return {
        editingPlanPortfolio: isEditingPlanPortfolio(state),
        currentPlan: throwErrorOnNullOrUndefined(state.session!.currentPlan),
        allFlashReportSummaries: getSuccessData(allFlashReportsSummariesSelector(state))!,
        allMeetingNoteSummaries: getSuccessData(allMeetingNotesSelector(state))!,
        oddIqEnabled: oddIqEnabled(state),
    };
};

export const mapDispatchToProps = (dispatch: Dispatch): IProductPreviewPropsFromActions => {
    return {
        actions: bindActionCreators({
            removeProductFromPortfolio: productActions.removeProductFromPlanPortfolio,
        }, dispatch),
    };
};

const connectedComponent = connect<IProductPreviewPropsFromStore, IProductPreviewPropsFromActions>(
    mapStateToProps,
    mapDispatchToProps,
)(ProductPreviewComponent);

export default multipleApiRequesterWrapper(
    connectedComponent,
    [
        allMeetingsNotesRequestPair,
        allFlashReportsSummariesRequestPair,
    ],
);
