import * as React from "react";
import {Map} from "immutable";
import {ProductSummary} from "../../model/product/ProductSummary";
import {getSuccessData} from "../common/commonStates";
import {
    currentPlan,
    hasAllAccessEnabled,
    hasClientPortfolioAccess,
    hasClientResearchEnabled,
    hasDynamicResearchAccess,
    hasPortfolioAccess,
    hasResearchAccess,
    isEditingPlanPortfolio,
    planCountryName,
    portfolioProductSummariesSelector
} from "../../../mainReducerMapSelectors";
import {IApplicationRootState} from "../../../applicationState";
import {connect, useDispatch, useSelector} from "react-redux";
import {multipleApiRequesterWrapper} from "../common/MultipleApiRequesterWrapper";
import {allProductsRequestPair} from "../common/RequesterPairs";
import {IMrtTableProps, MrtTableComponent} from "../common/MrtTable.component";
import {MRT_Cell, MRT_ColumnDef} from "material-react-table";
import {FormattedMessage} from "react-intl";
import {
    DEFAULT_PAGINATION,
    IMrtTableColumnFilter,
    IMrtTablePagination,
    IMrtTableState
} from "../../model/MrtTableState.model";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import researchPageActions from "./ResearchPageActions";
import {scrollToElement} from "../../utils/browserUtil";
import {getTranslation} from "../../utils/translationUtil";
import {hasConsultantLevelAccess, hasResearchPageAccess} from "../../utils/sessionUtil";
import {RaisedButton} from "../common/RaisedButton";
import {defaultButtonStyle, prominentButtonStyle} from "../common/buttonStyles";
import {AdminActionTypes} from "./edit-portfolio/editPortfolioActions";
import {IPlanInfo} from "../../model/ClientsAndPlans.model";
import productActions from "../product/productActions";
import {NoAccessNotification} from "../base/header/NoAccessNotification";
import {ErrorComponent} from "../base/Error.component";
import {bindActionCreators, Dispatch} from "redux";
import {byRatingAndManagerNameAndProductName, fixBlankAndTranslate} from "../../utils/researchUtil";
import {NOT_RATED, NR_RATING} from "../../utils/ratingConstants";

export interface INewResearchPropsFromStore {
    productSummaries: Map<number, ProductSummary>;
    hasDynamicResearchAccess: boolean;
    hasAllAccessEnabled: boolean;
    planCountryName: string;
    currentPlan: IPlanInfo | undefined;
    hasClientResearchEnabled: boolean;
    hasResearchAccess: boolean;
    hasPortfolioAccess: boolean;
    clientPortfolioEnabled: boolean;
    editingPlanPortfolio: boolean;
}

export interface INewResearchPropsFromActions {
    actions: {
        removeProductFromPlanPortfolio: (backstopId: number) => void;
    };
}

export type INewResearchProps = INewResearchPropsFromStore & INewResearchPropsFromActions;

export const CLIENT_USER_HIDDEN_MESSAGE = "The Managers & Strategies section is currently hidden from client users of this plan.";
export const ADD_STRATEGIES_MESSAGE = "To add strategies: find the strategy detail page using the search box above " +
    "and select “Add to Portfolio”.";
export const REMOVE_STRATEGIES_MESSAGE = "To remove strategies: click the product name to expand and " +
    "select “Remove from Portfolio”.";

export const NewResearchPage: React.FunctionComponent<INewResearchProps> = (props) => {
    const [data, setData] = useState<ProductSummary[]>([]);
    const [resetTable, setResetTable] = useState<boolean>(false);

    const dispatch = useDispatch();

    const researchTableStateFromStore: IMrtTableState | undefined
        = useSelector((state: IApplicationRootState) => state.researchPageState);

    useEffect(() => {
        const productSummaries = getAllProductSummaries().sort(byRatingAndManagerNameAndProductName);
        setData(productSummaries);
    }, [props]);

    useEffect(() => {
        if (researchTableStateFromStore?.scrollToBackstopId) {
            setTimeout(() => {
                scrollToElement(`#id_${researchTableStateFromStore.scrollToBackstopId}`, 100, true);
            }, (50));
        }
    }, []);

    const managerNameLink = ({cell}: { cell: MRT_Cell<ProductSummary> }) => {
        const mangerName = cell.getValue<string>();
        const backstopId = cell.row.original.manager.managerBackstopId;
        return (
            <Link to={`/managers/${backstopId}`}
                  onClick={() => dispatch(researchPageActions.setScrollToBackstopAction(backstopId))}
            >
                <div id={`id_${backstopId}`}>{mangerName}</div>
            </Link>
        );
    };
    const strategyNameLink = ({cell}: { cell: MRT_Cell<ProductSummary> }) => {
        const productName = cell.getValue<string>();
        const backstopId = cell.row.original.backstopId;
        return (
            <Link to={`/products/${backstopId}`}
                  onClick={() => dispatch(researchPageActions.setScrollToBackstopAction(backstopId))}>
                <div id={`id_${backstopId}`}>{productName}</div>
            </Link>
        );
    };

    const removeProductLink = ({cell}: { cell: MRT_Cell<ProductSummary> }) => {
        const backstopId = cell.row.original.backstopId;
        return <div className="new-research__remove-link"
                    onClick={() => props.actions.removeProductFromPlanPortfolio(backstopId)}>X</div>;
    };

    const mapAndTranslateOddRating = (oddRating: string) => {
        oddRating = oddRating.replace(NR_RATING, NOT_RATED);
        return getTranslation(`research-product-rating.${oddRating}`, oddRating);
    };

    const dataMapper = (productSummary: ProductSummary) => {
        return {
            ...productSummary,
            managerName: productSummary.manager.name,
            oddRatingCurrent: mapAndTranslateOddRating(productSummary.oddRating.rating),
        };
    };

    const getAllProductSummaries = (): ProductSummary[] => {
        return props.productSummaries
            .map(dataMapper)
            .valueSeq()
            .toArray()
            .map((it: ProductSummary) => fixBlankAndTranslate(it));
    };

    const columns: MRT_ColumnDef<ProductSummary>[] = [
        {
            accessorKey: "managerName",
            header: getTranslation("research-page.manager-name", "Manager Name"),
            Cell: managerNameLink,
            size: 200,
            filterVariant: "text"
        },
        {
            accessorKey: "name",
            header: getTranslation("research-page.strategy-name", "Strategy Name"),
            Cell: strategyNameLink,
            size: 100,
            filterVariant: "text"
        },
        {
            accessorKey: "assetType",
            header: getTranslation("research-page.asset-class", "Asset Class"),
            enableEditing: true,
            size: 100
        },
        {accessorKey: "productRating", header: getTranslation("research-page.rating", "Rating"), size: 100},
        {accessorKey: "oddRatingCurrent", header: getTranslation("research-page.odd-rating", "ODD Rating"), size: 100},
        {accessorKey: "approach", header: getTranslation("research-page.approach", "Approach"), size: 100},
        {accessorKey: "category", header: getTranslation("research-page.category", "Category"), size: 100},
        {accessorKey: "region", header: getTranslation("research-page.region", "Region"), size: 100},
        {accessorKey: "style", header: getTranslation("research-page.style", "Style"), size: 100},
        {
            accessorKey: "remove",
            header: getTranslation("research-page.remove", "Remove"),
            Cell: removeProductLink,
            enableColumnFilter: false,
            size: 100
        },
    ];

    const onPaginationChange = (pagination: IMrtTablePagination) => {
        dispatch(researchPageActions.updatePaginationAction(pagination));
        setResetTable(false);
    };

    const onGlobalFilterChange = (value: string) => {
        dispatch(researchPageActions.updateGlobalFilterAction(value));
        setResetTable(false);
    };

    const onColumnFiltersChange = (filters: IMrtTableColumnFilter[]) => {
        dispatch(researchPageActions.updateColumnFiltersAction(filters));
        setResetTable(false);
    };

    const renderEditControlButton = () => {
        if (!hasConsultantLevelAccess() || props.hasDynamicResearchAccess
            || props.hasAllAccessEnabled || props.planCountryName === "UK") {
            return;
        }

        return props.editingPlanPortfolio
            ? renderDoneEditingButton()
            : renderEditPortfolioButton();
    };

    const tableOptions: IMrtTableProps<ProductSummary> = {
        columns,
        data,
        header: renderEditControlButton,
        onColumnFiltersChange,
        onGlobalFilterChange,
        onPaginationChange,
        columnFilters: researchTableStateFromStore?.columnFilters ? researchTableStateFromStore.columnFilters : [],
        globalFilter: researchTableStateFromStore?.globalFilterState
            ? researchTableStateFromStore!.globalFilterState
            : "",
        pagination: researchTableStateFromStore?.pagination
            ? researchTableStateFromStore.pagination
            : DEFAULT_PAGINATION,
        reset: resetTable,
        columnVisibility: props.editingPlanPortfolio ? {} : {remove: false},
    };

    const renderEditingPortfolioHeader = () => {
        return <div className="new-research__editing-header" data-testid="editing-header">
            <h1>Editing Client Portfolio: {props.currentPlan?.name}</h1>
            <div className="new-research__help-text">
                <div>{REMOVE_STRATEGIES_MESSAGE}</div>
                <div>{ADD_STRATEGIES_MESSAGE}</div>
            </div>
        </div>;
    };

    const renderEditPortfolioButton = () => {
        const onChangeHandler = () => {
            dispatch({type: AdminActionTypes.BEGIN_EDIT_PORTFOLIO});
        };

        return <RaisedButton
            className="new-research__edit-portfolio-button"
            style={ defaultButtonStyle }
            onClick={onChangeHandler}
            primary={true}
        >
            <FormattedMessage id="research.edit-portfolio" defaultMessage="Edit Portfolio" />
        </RaisedButton>;
    };

    const renderDoneEditingButton = () => {
        const onChangeHandler = () => {
            dispatch({type: AdminActionTypes.END_EDIT_PORTFOLIO});
        };

        return <RaisedButton
            className="new-research__done-portfolio-button"
            style={prominentButtonStyle}
            onClick={onChangeHandler}
            primary={true}>
            <FormattedMessage id="research.done-editing" defaultMessage="Done Editing" />
        </RaisedButton>;
    };

    const renderNoResearchNotification = () => {
        return !props.hasClientResearchEnabled
            ? <NoAccessNotification
                message={getTranslation("research.hidden-from-clients", CLIENT_USER_HIDDEN_MESSAGE)}/>
            : null;
    };

    const render = () => {
        if (!hasResearchPageAccess(
            props.hasResearchAccess, props.hasPortfolioAccess, props.clientPortfolioEnabled)) {
            return <ErrorComponent/>;
        }

        return <div className="new-research__container" data-testid="new-research__container">
            {renderNoResearchNotification()}
            {props.editingPlanPortfolio && renderEditingPortfolioHeader()}
            <MrtTableComponent {...tableOptions}/>
        </div>;
    };

    return render();
};

export const mapStateToProps = (state: IApplicationRootState): INewResearchPropsFromStore => {
    return {
        productSummaries: getSuccessData(portfolioProductSummariesSelector(state))!,
        hasDynamicResearchAccess: hasDynamicResearchAccess(state),
        hasAllAccessEnabled: hasAllAccessEnabled(state),
        planCountryName: planCountryName(state),
        currentPlan: currentPlan(state),
        editingPlanPortfolio: isEditingPlanPortfolio(state),
        hasClientResearchEnabled: hasClientResearchEnabled(state),
        hasResearchAccess: hasResearchAccess(state),
        hasPortfolioAccess: hasPortfolioAccess(state),
        clientPortfolioEnabled: hasClientPortfolioAccess(state)
    };
};

export const mapDispatchToProps = (dispatch: Dispatch): INewResearchPropsFromActions => {
    return {
        actions: bindActionCreators({
            removeProductFromPlanPortfolio: productActions.removeProductFromPlanPortfolio,
        }, dispatch),
    };
};

const connectedNewResearchPage = connect<INewResearchPropsFromStore, INewResearchPropsFromActions>(
    mapStateToProps,
    mapDispatchToProps
)(NewResearchPage);

export default multipleApiRequesterWrapper(
    connectedNewResearchPage,
    [
        allProductsRequestPair()
    ],
);
