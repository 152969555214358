import * as React from "react";
import {useEffect} from "react";
import {CellTree, Tree, useTree} from "@table-library/react-table-library/tree";
import {Body, Cell, Header, HeaderCell, HeaderRow, Row, Table} from "@table-library/react-table-library/table";
import {formatCurrency, formatPercentBasedOnLocale} from "../../utils/numberUtil";
import {useTheme} from "@table-library/react-table-library/theme";
import {getTheme} from "@table-library/react-table-library/baseline";
import {Link} from "react-router-dom";
import {portfolioTreeFilteredByDate} from "./portfolioUtil";
import {IPlanTreeNode, IPlanTreeNodeFlat} from "../../model/Portfolio.model";
import {ISelectValue} from "../common/Select.component";
import {useDispatch, useSelector} from "react-redux";
import {Action, IdState} from "@table-library/react-table-library/types/common";
import {setTreeSelectedProductAction, updateTreeExpandedRowsAction} from "./PortfolioActions";
import {scrollToElement} from "../../utils/browserUtil";
import {hasResearchAccess} from "../../../mainReducerMapSelectors";
import {portfolioPlanTreeExpandedRows, portfolioPlanTreeSelectedProduct} from "./PortfolioMapSelectors";
import {AonColors} from "../../../../css/Colors";

export interface IPlanTreeTableProps {
    selectedDate: ISelectValue;
    datedPlanNodes: IPlanTreeNodeFlat[];
}
export const PlanTreeTable: React.FunctionComponent<IPlanTreeTableProps> = (props) => {
    const [nodes, setNodes] = React.useState<IPlanTreeNode[]>([]);
    const hasResearch = useSelector(state => hasResearchAccess(state));
    const expandedRowsFromStore = useSelector(state => portfolioPlanTreeExpandedRows(state));
    const selectedProduct = useSelector(state => portfolioPlanTreeSelectedProduct(state));
    const dispatch = useDispatch();

    useEffect(() => {
        if (selectedProduct) {
            setTimeout(() => {
                scrollToElement(`#link-${selectedProduct}`, 100, true);
            }, (10));
        }
    }, []);

    useEffect(() => {
        const date = (props.selectedDate.id as string);
        setNodes(portfolioTreeFilteredByDate(props.datedPlanNodes, date));
    }, [props]);

    const data = {nodes};

    const onTreeChange = (action: Action, state: IdState) => {
        const expandedRowsToStore = state.ids.map((id) => id as number);
        dispatch(updateTreeExpandedRowsAction(expandedRowsToStore));
    };

    const theme = useTheme([
        getTheme(),
        {
            Table: `
            --data-table-library_grid-template-columns:  55% 15% 15% 15%;
            border: 1px solid ${AonColors.AonGray05};
            border-radius:12px;
          `,
            HeaderRow: `
            height: 100px;
          `,
            Row: `
             border-top: 1px solid ${AonColors.AonGray05};
             border-bottom: 1px solid ${AonColors.AonGray05};
             font-face: Helvetica Now Text;
             font-weight: 400;
             font-size: 14px;
             line-height: 18px;
             bottom-margin: 12px;
             color: ${AonColors.AonNavy};             
            `,
            HeaderCell: `
            font-face: Helvetica Now Text;
            font-weight: 700;
            font-size: 14px;
            line-height: 18px;
            bottom-margin: 12px;
            color: ${AonColors.AonNavy};
            border-bottom: 1px solid ${AonColors.AonGray05};
            border-right: 1px solid ${AonColors.AonGray05};
            cursor: default;
            &:nth-last-child(1) {
                border-right: none;
            }
            `,
            BaseCell: `
            height: 60px;
            text-align: right;
            &:nth-of-type(1) {
                text-align: left;
            }
          `,
            Cell: `
            cursor: default;
            &:a {
               cursor: pointer;
            }
            `,
        },
    ]);

    const tree: Tree<IPlanTreeNode> = useTree(data,
        {onChange: onTreeChange, state: {ids: expandedRowsFromStore}},
        {treeIcon: {
            iconRight: (
                <div className="far fa-chevron-right fa-chevron-tree"/>
            ),
            iconDown: (
                <div className="far fa-chevron-down  fa-chevron-tree"/>
            ),
        }},
    );


    const renderName = (item: IPlanTreeNode) => {
        return hasResearch && item.backstopId
            ? <Link to={`/products/${item.backstopId}`}
                    onClick={() => dispatch(setTreeSelectedProductAction(item.id))} id={`link-${item.id}`}>
                {item.name}
            </Link>
            : item.name;
    };

    const expandCollapse = () => {
        tree.fns.onToggleAll({isCarryForward: false, isPartialToAll: true});
    };

    const renderTree = () => {
        return (
            <Table data={data} tree={tree} theme={theme} layout={{custom: true}}>
                {(nodes: IPlanTreeNode[]) => (
                    <>
                        <Header>
                            <HeaderRow>
                                <HeaderCell>Category</HeaderCell>
                                <HeaderCell>% Total</HeaderCell>
                                <HeaderCell>% Category</HeaderCell>
                                <HeaderCell>Amount</HeaderCell>
                            </HeaderRow>
                        </Header>

                        <Body>
                            {nodes.map((item: IPlanTreeNode) => (
                                <Row key={item.id} item={item}
                                     className={item.nodes && item.nodes.length > 0 ? "portfolio__parent-node" : ""}>
                                    <CellTree item={item}>
                                        {renderName(item)}
                                    </CellTree>
                                    <Cell>{formatPercentBasedOnLocale(item.marketValuePctOfTotal)}</Cell>
                                    <Cell>{formatPercentBasedOnLocale(item.marketValuePctOfParent)}</Cell>
                                    <Cell>{formatCurrency(item.marketValue)}</Cell>
                                </Row>
                            ))}
                        </Body>
                    </>
                )}
            </Table>
        );
    };

    return <div className="portfolio-header-tree-container">
        <div className="portfolio__header-container">
            <h4>Details</h4>
            <div className="link-14 clickable" onClick={expandCollapse}>Expand/Collapse All</div>
        </div>
        <div className="portfolio__tree-container">
            {renderTree()}
        </div>
    </div>;
};