import * as React from "react";
import {useEffect, useState} from "react";
import {
    currentPlan,
    currentProductPageTab,
    hasClientResearchEnabled,
    hasResearchAccess,
    isEditingPlanPortfolio,
    oddIqEnabled,
    planCountryName,
    portfolioProductSummariesSelector,
    productUniverseSummariesSelector,
    userType
} from "../../../mainReducerMapSelectors";
import {getSuccessData, REQUEST_STATES, RequestState} from "../common/commonStates";
import {AnyAction, bindActionCreators, Dispatch} from "redux";
import productActions, {
    addProductToPlanPortfolio,
    clearTickerData,
    requestMostRecentInReports,
    requestProduct,
    setActiveProduct,
    setCurrentProductTab
} from "./productActions";
import {connect, useDispatch} from "react-redux";
import {FormattedMessage, injectIntl, IntlShape} from "react-intl";
import {multipleApiRequesterWrapper} from "../common/MultipleApiRequesterWrapper";
import {allProductsRequestPair, shareClassRequestPair} from "../common/RequesterPairs";
import {ErrorComponent} from "../base/Error.component";
import {LoadingSpinner} from "../icons/LoadingSpinner.component";
import {Link, useHistory} from "react-router-dom";
import {Product} from "../../model/product/Product.model";
import {ApiError} from "../../model/ApiError.model";
import {IPlanInfo} from "../../model/ClientsAndPlans.model";
import {UserTypeEnum} from "../../model/UserInfo.model";
import {Map as ImmutableMap, Map} from "immutable";
import {ProductSummary} from "../../model/product/ProductSummary";
import {IOddResponse, IOddSectionsQuestions} from "../../api/OddIqApi";
import {InvestmentDueDiligence} from "./due-diligence/InvestmentDueDiligence";
import ProductFlashReports from "./flash-reports/ProductFlashReports";
import ProductMeetingNotes from "./meeting-notes/ProductMeetingNotes";
import {NOT_RATED, NR_RATING} from "../../utils/ratingConstants";
import {OperationalDueDiligence} from "./operational-due-diligence/OperationalDueDiligence";
import {getQueryStringParam} from "../../utils/browserUtil";
import {RaisedButton} from "../common/RaisedButton";
import {defaultButtonStyle} from "../common/buttonStyles";
import {
    oddProductDateRespondedSelector,
    oddResponsesByBackstopSelector,
    operationalDueDiligenceSectionsQuestionsSelector
} from "../odd-iq/OddIqRequesterPairs";
import {
    requestOddProductDateRespondedForCurrentPlan,
    requestOddResponsesByBackstopForCurrentPlan,
    requestOddSectionsAndQuestions
} from "../odd-iq/OddIqActions";
import {IApplicationRootState} from "../../../applicationState";
import {InformInvestmentDueDiligence} from "../inform/InformInvestmentDueDiligence";
import {ProductRatingType} from "../../model/product/ProductRatingType";
import {InFormReportingApi} from "../../api/InFormReportingApi";
import {IInformProduct, IInformProps} from "../../model/inform/InformProduct";
import {IInformPerformance} from "../../model/inform/InformPerformance";
import {ProductPerformanceAnalytics} from "./performance-analytics/ProductPerformanceAnalytics";

export enum ProductPageTab {
    IDD = "INVESTMENT DUE DILIGENCE",
    PERFORMANCE_ANALYTICS = "PERFORMANCE ANALYTICS",
    ODD = "OPERATIONAL DUE DILIGENCE",
    FLASH_REPORTS = "FLASH REPORTS",
    MEETING_NOTES = "MEETING NOTES",
}

export interface IProductPropsFromActions {
    actions: {
        setActiveProduct: typeof setActiveProduct;
        requestProduct: typeof requestProduct;
        addProductToPlanPortfolio: typeof addProductToPlanPortfolio;
        clearTickerData: typeof clearTickerData;
        requestMostRecentInReports: typeof requestMostRecentInReports;
    };
}

export interface IProductPropsFromLocation {
    match: {
        params: {
            productBackstopId: string;
        };
    };
}

export interface IProductPagePropsFromStore {
    product?: Product;
    productLoading?: boolean;
    productError?: ApiError;
    editingPlanPortfolio: boolean;
    currentPlan: IPlanInfo;
    userType: UserTypeEnum;
    planCountryName: string;
    portfolioProductSummaries: Map<number, ProductSummary>;
    productUniverseSummaries: Map<number, ProductSummary>;
    oddProductQuestionResponse: RequestState<IOddResponse[]>;
    oddIqEnabled: boolean;
    hasClientResearchEnabled: boolean;
    hasResearchAccess: boolean;
    oddSectionsQuestions: RequestState<IOddSectionsQuestions>;
    oddProductDateResponded: RequestState<ImmutableMap<number, string>>;
    currentTab?: ProductPageTab;
}

interface IProductPropsFromLocationFromIntl {
    intl: IntlShape;
}

export type ProductPageProps = IProductPropsFromLocation &
    IProductPropsFromActions &
    IProductPagePropsFromStore &
    IProductPropsFromLocationFromIntl;

export const ProductPage: React.FunctionComponent<ProductPageProps> = (props) => {
    const [informProps, setInformProps] = useState<IInformProps | undefined>(undefined);
    const [renderErrorComponent, setRenderErrorComponent] = useState<boolean>(false);
    const [informLoaded, setInformLoaded] = useState<boolean>(true);

    const queryStringTab = getQueryStringParam("tab");
    const dispatch = useDispatch();
    const history = useHistory();

    const productBackstopId = Number.parseInt(props.match.params.productBackstopId, 10);

    const {product} = props;

    const isProductAccessible = props.portfolioProductSummaries.has(productBackstopId)
            || props.editingPlanPortfolio;

    const getManager = () => {
        return props.productUniverseSummaries.get(props.product!.backstopId)!.manager;
    };

    const requestProductDetails = () => {
        if(isProductAccessible) {
            props.actions.requestProduct(productBackstopId);
            props.actions.clearTickerData();
            props.actions.requestMostRecentInReports(productBackstopId);
        }
    };

    const requestOddData = () =>{
        if(props.oddIqEnabled) {
            if (props.oddProductQuestionResponse.kind === REQUEST_STATES.NOT_REQUESTED)
                dispatch(requestOddResponsesByBackstopForCurrentPlan);

            if (props.oddSectionsQuestions.kind === REQUEST_STATES.NOT_REQUESTED)
                dispatch(requestOddSectionsAndQuestions);

            if (props.oddProductDateResponded.kind === REQUEST_STATES.NOT_REQUESTED)
                dispatch(requestOddProductDateRespondedForCurrentPlan);
        }
    };

    const renderManagerDetails = () => {
        const manager = getManager();

        return <Link to={`/managers/${manager.managerBackstopId}`} className={"product__manager-link old-anchor"}>
            <div className="product__manager-details product__content-align card--hover paper-style">
                <div className={"product__manager-name title-xs"}>
                    {manager.name}
                </div>
                <div className={"product__manager-flavor body-14"}>
                    <FormattedMessage
                        id="product.manager-flavor"
                        defaultMessage="Firm-Level Flash Reports, Meeting Notes, Strategies"
                    />
                </div>
                <div className="navigation__forward-arrow-small fa-regular fa-arrow-right"/>
            </div>
        </Link>;
    };

    const getSelected = (tab: ProductPageTab) => props.currentTab
        ? props.currentTab === tab ? "selected" : ""
        : "";

    const getFrench = () => props.intl.locale === "fr-FR"
    ? "french"
        : "";

    const getKeyFromValue = (tab: ProductPageTab): string => {
        return Object.keys(ProductPageTab)
            .filter((key: keyof typeof ProductPageTab) => (ProductPageTab[key] === tab))[0];
    };

    const renderTab = (tab: ProductPageTab) => {
        return <div id={`product__tab-${getKeyFromValue(tab).toLowerCase()}`}
                    className={`product__tab clickable ${getSelected(tab)} ${getFrench()}`}
        onClick={() => dispatch(setCurrentProductTab(tab))}>
            <FormattedMessage
                id={`product-tab.${tab}`}
                defaultMessage={tab}
            />
        </div>;
    };

    const hasResearchAndRated = () => {
        return (props.hasClientResearchEnabled || props.hasResearchAccess)
            && product!.currentRating.value !== NOT_RATED;
    };

    const hasResearchAccess = () => {
        return props.hasClientResearchEnabled || props.hasResearchAccess;
    };

    const hasOddDetails = () => {
        return !!getSuccessData(props.oddProductQuestionResponse)?.find(
            (response) => response.productBackstopId === productBackstopId
        );
    };

    const hasOddIq = () => {
        return  props.oddIqEnabled && hasOddDetails();
    };

    const hasOdd = (): boolean => {
        const rating = product!.oppDueDiligenceRatings!.rating!;
        return !(!rating || rating === NR_RATING) || hasOddIq();
    };

    const renderTabs = () => {
        return <div className="product__tabs-container">
            {hasResearchAndRated() ? renderTab(ProductPageTab.IDD) : null}
            {showPerformanceAnalyticsTab()
                ? renderTab(ProductPageTab.PERFORMANCE_ANALYTICS)
                : null
            }
            {hasOdd() ? renderTab(ProductPageTab.ODD) : null}
            {hasResearchAndRated() ? renderTab(ProductPageTab.FLASH_REPORTS) : null}
            {hasResearchAndRated() ? renderTab(ProductPageTab.MEETING_NOTES): null}
        </div>;
    };

    const showPerformanceAnalyticsTab = () => {
        const performance = informProps?.performance as IInformPerformance;
        return informProps?.summary?.relativePerformance?.length
            || (performance && (performance.productCumulative.length || performance.monthlyExcessReturnValues.length));
    };

    const isInformProduct = () => {
        return product!.currentRating.ratingType === ProductRatingType.INFORM;
    };
    const renderIdd = () => {
        const MP_COLLAPSED_LENGTH = 500;
        return props.currentTab === ProductPageTab.IDD && hasResearchAndRated()
        ? isInformProduct()
            ? <InformInvestmentDueDiligence product={product!}
                                            informProduct={informProps as IInformProduct}
                                            oddIqEnabled={props.oddIqEnabled}
                                            renderErrorComponent={renderErrorComponent}/>
            : <InvestmentDueDiligence product={props.product!}
                                  oddIqEnabled={props.oddIqEnabled}
                                  userType={props.userType}
                                  mpCollapsedLength={MP_COLLAPSED_LENGTH}
        />
            : null;
    };

    const renderOdd = () => {
        return props.currentTab === ProductPageTab.ODD
            ? <OperationalDueDiligence
                product={product!}
                oddIqEnabled={props.oddIqEnabled}
                oddProductQuestionResponse={getSuccessData(props.oddProductQuestionResponse)!}
                oddSectionsQuestions={getSuccessData(props.oddSectionsQuestions)!}
                oddProductDateResponded={getSuccessData(props.oddProductDateResponded)!}
            />
            : null;
    };

    const renderFlashReports = () => {
        return props.currentTab === ProductPageTab.FLASH_REPORTS
            ? <ProductFlashReports productBackstopId={productBackstopId}/>
            : null;
    };

    const renderMeetingNotes = () => {
        return props.currentTab === ProductPageTab.MEETING_NOTES
            ? <ProductMeetingNotes productBackstopId={productBackstopId}/>
            : null;
    };

    const renderPerformanceAnalytics = () => {
        return props.currentTab === ProductPageTab.PERFORMANCE_ANALYTICS && informProps
            ? <ProductPerformanceAnalytics
                informProduct={informProps}/>
            : null;
    };

    const shouldRenderAddToPortfolioButton = () => {
        return (props.editingPlanPortfolio &&
            !props.portfolioProductSummaries.has(productBackstopId));
    };

    const shouldRenderAddedToPortfolioText = () => {
        return (props.editingPlanPortfolio &&
            props.portfolioProductSummaries.has(productBackstopId));
    };

    const renderAddToPortfolioButton = () => {
        return <RaisedButton
            className="add-to-portfolio-button"
            style={defaultButtonStyle}
            primary={true}
            onClick={
                () => props.actions.addProductToPlanPortfolio(props.product!)
            }>
            Add to Portfolio
        </RaisedButton>;
    };

    const renderAddedToPortfolioText = () => {
        return <div className="added-to-portfolio-text">ADDED TO PORTFOLIO</div>;
    };

    const setCurrentTab = () => {
        if(props.product) {
            let tab = ProductPageTab.IDD;
            if (queryStringTab === "ODD" && hasOdd()){
                tab = ProductPageTab.ODD;
            }
            else if(!hasResearchAndRated() && hasOdd()){
                tab = ProductPageTab.ODD;
            }
            dispatch(setCurrentProductTab(tab));
        }
    };

    useEffect(() => {
        requestProductDetails();
    }, [props.match.params.productBackstopId]);

    useEffect(() => {
        if (history.action !== "POP" || !props.currentTab) {
            setCurrentTab();
        }
        if(props.product) {
            if(isInformProduct()) {
                InFormReportingApi.fetchInformProduct(productBackstopId).then((response: any) => {
                    setInformProps(response);
                    setInformLoaded(false);
                }).catch(() => {
                    setRenderErrorComponent(true);
                    setInformLoaded(false);
                });
            } else {
                InFormReportingApi.fetchInformDetailsForFundamentalProduct(productBackstopId)
                    .then((response: any) => {
                        setInformProps(response);
                        setInformLoaded(false);
                    }).catch(() => {
                    setInformLoaded(false);
                });
            }
        }
    }, [props.product]);

    useEffect(() => {
        requestOddData();
    }, []);

    if (!isProductAccessible) {
        return <ErrorComponent/>;
    }

    if(!product || props.productLoading || props.productLoading === undefined || informLoaded) {
        return <LoadingSpinner/>;
    }

    if (!hasResearchAndRated() && !hasOdd()) {
        return <ErrorComponent/>;
    }

    return <div className="main-content new-common-styles">
            <div className="product__content">
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}>
                    <h1 style={{flexBasis: "75%", marginBottom: props.oddIqEnabled ? "20px" : "40px"}}
                        className="product__title product__content-align blue">
                        {product!.name}
                    </h1>
                    {shouldRenderAddToPortfolioButton() ? renderAddToPortfolioButton() : null}
                    {shouldRenderAddedToPortfolioText() ? renderAddedToPortfolioText() : null}
                </div>
                {hasResearchAccess() ? renderManagerDetails() : null}
                {renderTabs()}
                {renderIdd()}
                {renderPerformanceAnalytics()}
                {renderOdd()}
                {renderFlashReports()}
                {renderMeetingNotes()}
            </div>
        </div>;
};

export const mapStateToProps = (state: IApplicationRootState): IProductPagePropsFromStore => {
    return {
        product: state.productPageState!.product,
        productLoading: state.productPageState!.productLoading,
        productError: state.productPageState!.productError,
        editingPlanPortfolio: isEditingPlanPortfolio(state),
        currentPlan: currentPlan(state),
        userType: userType(state),
        planCountryName: planCountryName(state),
        portfolioProductSummaries: getSuccessData(portfolioProductSummariesSelector(state))!,
        productUniverseSummaries: getSuccessData(productUniverseSummariesSelector(state))!,
        oddProductQuestionResponse: oddResponsesByBackstopSelector(state),
        oddIqEnabled: oddIqEnabled(state),
        hasClientResearchEnabled: hasClientResearchEnabled(state),
        hasResearchAccess: hasResearchAccess(state),
        oddSectionsQuestions: operationalDueDiligenceSectionsQuestionsSelector(state),
        oddProductDateResponded: oddProductDateRespondedSelector(state),
        currentTab: currentProductPageTab(state),
    };
};

export const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): IProductPropsFromActions => {
    return {
        actions: bindActionCreators({
            ...productActions,
        }, dispatch),
    };
};

const connectedProductPage =
    connect<IProductPagePropsFromStore, IProductPropsFromActions, IProductPropsFromLocation>(
        mapStateToProps,
        mapDispatchToProps,
    )(ProductPage);

export default injectIntl(multipleApiRequesterWrapper(
    connectedProductPage,
    [
        allProductsRequestPair(),
        shareClassRequestPair,
    ],
));